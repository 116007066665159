import React from "react"
import { Link } from "gatsby"

export function PaymentSuccess({paymentIntent}) {
  return (
    <div className="mb-20">
      <div className="grid grid-cols-1 lg:grid-cols-3">

        <div className="lg:col-start-2 col-span-1">
        <p className="text-lg text-gray-900 mb-3">
          Hello,
        </p>
        <p className="text-lg text-gray-900 mb-3">
          I'm humbled to receive your financial support.
          It truly means a lot to me!
        </p>
        <p className="text-lg text-gray-900 mb-3">
          Don't forget to vote on Oct 15th, tell your friends!
        </p>
        <p className="text-lg text-gray-900">
          Thank you,<br />
          Armand Hurford
        </p>
        <p className="mt-3 text-xs text-gray-500">
          You will see your ${paymentIntent.amount/100} donation on your credit card statement
          listed as '{ paymentIntent.charges.data[0].calculated_statement_descriptor }'
        </p>
        </div>

      </div>
    </div>
  )
}

export function PaymentError({error}) {
  return (
    <div className="mb-20 mx-auto max-w-7xl px-4 sm:px-6">
      <div className="text-center">
        <h1 className="text-4xl tracking-tight text-gray-900">
          <span className="block">There was an error with your payment</span>
        </h1>
        <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
          <Link to="/donate" className="underline text-gray-600">Please try again</Link>
        </p>
      </div>
    </div>
  )
}
