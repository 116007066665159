// URL to use for testing:
// http://localhost:8000/thank_you/?payment_intent=pi_3LVpQRJY8aiIh8bx1a2FkNfr&payment_intent_client_secret=pi_3LVpQRJY8aiIh8bx1a2FkNfr_secret_N4bVOQfolwqe3ay0u1VP21Bhc&redirect_status=succeeded

import React, {useEffect, useState} from "react";
import { navigate } from "gatsby";

import Layout from "components/Layout"
import { Container } from "components/Container"
import PurchaseAPI from "api/purchase"
import { PaymentError, PaymentSuccess } from "components/Payment"

const ThankYouPage = ({location}) => {
  const [paymentIntent, setPaymentIntent] = useState(null)
  const [paymentIntentError, setPaymentIntentError] = useState(null)

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const payment_intent_id = params.get("payment_intent");

    if(!payment_intent_id){ navigate("/") }

    PurchaseAPI.retrieveIntent({ payment_intent_id })
    .then((result) => {
      result.object === "payment_intent" ?
        setPaymentIntent(result) :
        setPaymentIntentError(result)
    })
  }, [location.search]);

  return (
    <Layout>
      <Container>
        { paymentIntentError && <PaymentError error={paymentIntentError} /> }
        { paymentIntent && <PaymentSuccess paymentIntent={paymentIntent} /> }
      </Container>
    </Layout>
  )
}

export default ThankYouPage
export { Head } from "components/Head"
