const createIntent = ({ amount, metadata, email }) => {
  return fetch("/.netlify/functions/purchase-intent-create", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        amount: amount,
        metadata: metadata || {},
        receipt_email: email,
      }),
    })
    .then((response) => response.json())
}

const retrieveIntent = ({ payment_intent_id }) => {
  return fetch("/.netlify/functions/purchase-intent-retrieve", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ payment_intent_id }),
    })
    .then((response) => response.json())
}

export default {
  createIntent: createIntent,
  retrieveIntent: retrieveIntent,
}
